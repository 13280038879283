import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

const THEME = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#81ecec',
    },
    secondary: {
      main: '#fab1a0',
    },
  },
});

const ThemedApp: React.FC = () => (
  <ThemeProvider theme={THEME}>
    <App />
  </ThemeProvider>
)

ReactDOM.render(<ThemedApp />, document.getElementById('root'));

serviceWorker.register();
