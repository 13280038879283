import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const APP_NAME = 'Epic Story App';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '100vh',
    width: '100vw',
    margin: 0,
    padding: 0,
    background: theme.palette.background.default,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  view: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flexGrow: 5,
    padding: theme.spacing(2),
  },
  appBar: {},
  appName: {
    userSelect: 'none',
  },
}))

const App: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="relative" color="default">
        <Toolbar>
          <Typography className={classes.appName} variant="h4" color="textPrimary">{APP_NAME}</Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.view}>
        <Typography color="textPrimary">Hello World</Typography>
      </div>
    </div>
  );
};

export default App;
